import React from 'react'
import styles from './bio.module.css'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({  
  large: {
    width: "150px",
    height: "150px",    
  },
}));
export default function ImageAvatars({ bio }) {
  const classes = useStyles();

  return (
    <div >
    <Avatar  alt={bio.name} src={bio.heroImage.fluid.src} className={classes.large}></Avatar>
    <h3 className={styles.previewTitle}>
    {bio.name}
    </h3>
    <h4>{bio.title}</h4>
    <p
      dangerouslySetInnerHTML={{
        __html: bio.shortBio.childMarkdownRemark.html,
      }}
    />
    </div>
  );
}
