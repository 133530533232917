import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import BioPreview from '../components/bio'
import { FaUsers } from 'react-icons/fa';
import navStyles from '../components/navigation.module.css';
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Logo from '../components/logo'

class Thuisfront extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const span = get(this, 'props.data.allContentfulPerson.edges')    
    return (
      <Layout>
        <Helmet title={siteTitle} />
        <Logo author={span[0]} />
        <div className="wrapper">
          <ul className="header-list">
          <li><div className={navStyles.iconimage}><FaUsers  className={navStyles.icon}/></div></li>
            <h2><u>H</u>ometeam</h2>          
          </ul>
          <hr className="hr-repeat"></hr>
          <br/>          
          <ul className="article-list">
            {span.map(({ node }) => {
              return (
                <li key={node.name}>
                  <BioPreview bio={node} />
                </li>
              )
            })}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default Thuisfront

export const pageQuery = graphql`
  query LeaIndexQuery {    
    allContentfulPerson(filter: { id: { ne: "e339d456-b3e8-5213-8d99-cf8ebb14a0a0" } }, sort: { fields: [title], order: DESC }) {
      edges {
        node {
          name
          shortBio  {
            childMarkdownRemark {
              html
            }
          }
          headline
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

